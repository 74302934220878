<template>
    <div id="undefined" class="page">
        <h1>404</h1>
        <p>Sorry pal there's no funny gimmik or 404 mini game here... yet.</p>
    </div>
</template>
<script>

export default {
    name: 'NotFound',
    mounted () {
        window.scrollTo(0, 0);
    }
}
</script>
<style lang="scss">
  
</style>