<template>
    <header id="app-header">
        <div id="logo">
            <router-link class="nav-link logo-link" to="/">C<span>G</span></router-link>
        </div>
        <div id="nav">
            <router-link class="nav-link" v-bind:class="{ active: this.$route.name == 'Gallery' }" to="/">Gallery</router-link>
            <router-link class="nav-link" v-bind:class="{ active: this.$route.name == 'Profile' || this.$route.name == 'Certifications' }" to="/profile">Profile</router-link>
            <router-link class="nav-link" v-bind:class="{ active: this.$route.name == 'Contact' }" to="/contact">Contact</router-link>
        </div>
    </header>
</template>
<script>
export default {
    name: 'Navigation',
    data: function() {
        return {
            activePage: this.$route.name
        }
    },
    methods: {
        mostrar: function() {
            console.log(this.$route.name);
        }
    }
}
</script>
<style lang="scss">
    @import '../assets/scss/utils.scss';

    #app-header {
        @media (min-width: $brk-mid) {
            position: fixed;
            left: 0;
            top: 0;
            width: 30%;
            height: 100vh;
            //background-color: white;
        }
        #logo {
            @include movile-sizing-nav("top");
            @include light-typo;
            
        }
        #nav {
            @include movile-sizing-nav("bottom");
        }
        .nav-link {
            transition: 0.5s;
            text-decoration: none;
            color: $foreground;
            font-size: 18px;
            cursor: pointer;
            position: relative;
            &::after {
                @include underline-center-link-style;
            }
            @media (min-width: $brk-movile) {
                font-size: calc(18px + 6 * ((100vw - 450) / 916));
            }
            @media (min-width: $brk-mid) {
                margin-top: 4vh;
            }
            @media (min-width: $brk-screen) {
                font-size: 24px;
            }
            &.active {
                &::after {
                    transform: scaleX(100%);
                }
            }
        }
        .logo-link {
            font-size: 36px;
            @media (min-width: $brk-movile) {
                font-size: calc(36px + 12 * ((100vw - 450) / 916));
            }
            @media (min-width: $brk-screen) {
                font-size: 48px;
            }
            span {
                font-weight: bold;
                @include bold-typo;
            }
        }
    }
</style>