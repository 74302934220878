import * as THREE from "three";
import MeshProducer from "./components/meshProducer";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import ShaderX from "../utils/shaders/shaderx";

import { TweenMax } from "gsap";

//VARIABLES
let scene, camera, renderer;
//Variables post procesamiento

var uMouse = new THREE.Vector2(0,0);
var targetSpeed = 0;

//const colorN = new THREE.Color( 0x000000 );
const colorB = new THREE.Color( 0xffffff );

let meshCollection;

//let offsetY = 0;

const images = [
	'https://carlosgamez.dev/pictures/rose.jpg',
	'https://carlosgamez.dev/pictures/artmixa.jpg',
	'https://carlosgamez.dev/pictures/megamiglass.jpg'
];


class GLGallery {
    constructor() {
		//variables internas
		this.speed = 0;
		this.mouse = new THREE.Vector2();
		this.followMouse = new THREE.Vector2();
		this.prevMouse = new THREE.Vector2();
		this.paused = false;
		//fin variables internas

        scene = new THREE.Scene({background: colorB});
		//camera = new THREE.PerspectiveCamera( 75, (window.innerWidth / 1.5) / window.innerHeight, 0.1, 1000 );
		camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );

		renderer = new THREE.WebGLRenderer( { antialias: true } );
		renderer.outputEncoding = THREE.sRGBEncoding;
		renderer.setSize( window.innerWidth , window.innerHeight  ); //renderer.setSize( (window.innerWidth / 1.5), window.innerHeight  );
		document.body.appendChild( renderer.domElement );

		scene.background = colorB;

		meshCollection = new MeshProducer(images, scene);
        //console.log(meshCollection);

        // meshCollection.traverse( function(child) {
		// 	if ( child.isMesh ) {
		// 		child.position.y -= offsetY;
        //         offsetY += 4.0;
		// 	}
        // });

		scene.add( meshCollection );

		camera.position.z = 2; //5

		document.addEventListener('keydown', event => {
			if(event.isComposing || event.keyCode === 40) {
				this.moveCamera(-4.0);
			}
		});

		// post processing
		this.composerPass();
		document.addEventListener('mousemove', (e) => {
			// mousemove / touchmove
			uMouse.x = ( e.clientX / window.innerWidth ) ;
			uMouse.y = 1. - ( e.clientY/ window.innerHeight );
		});

	  	//Post Process End

        this.animate();

		window.addEventListener( 'resize', this.onWindowResize );
    }
	composerPass(){
		this.composer = new EffectComposer(renderer);
		this.renderPass = new RenderPass(scene, camera);
		this.composer.addPass(this.renderPass);

		//custom shader pass
		var myEffect = {
		uniforms: {
			"tDiffuse": { value: null },
			"distort": { value: 0 },
			"resolution": { value: new THREE.Vector2(1.,window.innerHeight/window.innerWidth) },
			"uMouse": { value: new THREE.Vector2(-10,-10) },
			"uVelo": { value: 0 },
			"time": { value: 0 }
		},
		vertexShader: ShaderX.vertexShader,
		fragmentShader: ShaderX.fragmentShader
		}

		this.customPass = new ShaderPass(myEffect);
		this.customPass.renderToScreen = true;
		this.composer.addPass(this.customPass);
	} //composerPass End
	getSpeed(){
		this.speed = Math.sqrt( (this.prevMouse.x- uMouse.x)**2 + (this.prevMouse.y- uMouse.y)**2 );

		targetSpeed -= 0.1*(targetSpeed - this.speed);
		this.followMouse.x -= 0.1*(this.followMouse.x - uMouse.x);
		this.followMouse.y -= 0.1*(this.followMouse.y - uMouse.y);

		//console.log('buscando velocidad');
		this.prevMouse.x = uMouse.x;
		this.prevMouse.y = uMouse.y;
	}
    animate() {
		//renderer.render( scene, camera );
		this.getSpeed();
		//this.customPass.uniforms.uMouse.value = this.followMouse;
		this.customPass.uniforms.uMouse.value = uMouse;
		this.customPass.uniforms.uVelo.value = Math.min(targetSpeed, 0.05);
		targetSpeed *=0.999;
		//requestAnimationFrame( animate );
		this.composer.render()
    }
	onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight; //(window.innerWidth / 1.5) / window.innerHeight; // readjust the aspect ratio.
        camera.updateProjectionMatrix(); // Used to recalulate projectin dimensions.
        renderer.setSize( window.innerWidth , window.innerHeight  );//renderer.setSize( (window.innerWidth / 1.5), window.innerHeight  );
	}
	moveCamera(number) {
		TweenMax.to(camera.position, {
			y: (number * -4.0 ),
			delay:0.0,
			duration: 1.0,
			ease: "power2.out"
		});
	}
	stop() {
		this.paused = true;
	}
	play() {
		this.paused = false;
		this.render();
	}
    init() {
        console.log('Activado');
    }
}


export default GLGallery;