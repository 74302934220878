<template>
  <Navigation/>
  <div id="router-view">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <div id="white-screen" v-bind:class="{ active: (this.$route.name != 'Gallery') || (isMovile == true) }"></div>
    <WebGL/>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'
import WebGL from '@/components/WebGL.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    WebGL
  },
  data: function() {
    return {
      activeScreen: this.$route.name,
      isMovile: false
    }
  },
  mounted: function() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.isMovile = true;
        }
    }
}
</script>

<style lang="scss">
//Normalize
@import '../node_modules/normalize.css/normalize.css';
@import './assets/scss/utils.scss';

//Fonts
@font-face {
  font-family: "Spartan";
  src: local("Spartan"), 
  url("./assets/fonts/spartanmb-regular-webfont.woff2") format('woff2'),
  url("./assets/fonts/spartanmb-regular-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Spartan";
  src: local("Spartan"), 
  url("./assets/fonts/spartanmb-light-webfont.woff2") format('woff2'),
  url("./assets/fonts/spartanmb-light-webfont.woff") format('woff');
  font-weight: lighter;
}
@font-face {
  font-family: "Spartan";
  src: local("Spartan"), 
  url("./assets/fonts/spartanmb-medium-webfont.woff2") format('woff2'),
  url("./assets/fonts/spartanmb-medium-webfont.woff") format('woff');
  font-weight: bold;
}

#app {
  font-family: "Spartan";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @media (min-width: $brk-screen) {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  // }
}

#white-screen {
  transition: 0.5s;
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0;
  &.active {
    opacity: 1;
  }
}

#router-view {
  padding: 12.3vh 0;
  width: 100%;
  height: auto;
  @media (min-width: $brk-mid) {
    width: 70%;
    min-height: 100vh;
    padding: 0;
    float: right;
  }
}

.page {
  margin: 20% 7.47% 11% 7.47%;
  @media (min-width: $brk-mid) {
    margin: 15% 25vw 11% 5vw;
  }
}
.page-sub-title {
  @include page-sub-title;
}
.page-title {
  @include page-title;
}
.page-excerpt {
  @include page-excerpt;
}
.page-paragraph {
  @include page-paragraph;
}
.page-link {
  @include page-paragraph;
  @include page-link;
}

//Router animation
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}
</style>
