import { createStore } from 'vuex'

export default createStore({
  state: {
    entryIndex: 0,
    limit: 2
  },
  mutations: {
    increaseEntryIndex(state){
      if(state.entryIndex < state.limit) {
        state.entryIndex++;
      }
    },
    decreaseEntryIndex(state){
      if(state.entryIndex > 0) {
        state.entryIndex--;
      }
    },
    resetEntryIndex(state) {
      state.entryIndex = 0;
    }
  },
  actions: {
  },
  modules: {
  }
})
