const ShaderX = {
    vertexShader: `varying vec2 vUv;
			void main() {
				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0 );
			}`,
    fragmentShader:  `
			uniform float time;
			uniform sampler2D tDiffuse;
			uniform float uVelo;
			uniform vec2 resolution;
			varying vec2 vUv;
			uniform vec2 uMouse;
			float circle(vec2 uv, vec2 disc_center, float disc_radius, float border_size) {
			uv -= disc_center;
			uv*=resolution;
			float dist = sqrt(dot(uv, uv));
			return smoothstep(disc_radius+border_size, disc_radius-border_size, dist);
			}
			void main()  {
				vec2 newUV = vUv;
				float c = circle(newUV, uMouse, 0.0, 0.2);
				float r = texture2D(tDiffuse, newUV.xy += c * (uVelo * .7)).x;
				float g = texture2D(tDiffuse, newUV.xy += c * (uVelo * .725)).y;
				float b = texture2D(tDiffuse, newUV.xy += c * (uVelo * .75)).z;
				vec4 color = vec4(r, g, b, 1.);

				gl_FragColor = color;
			}`
}

export default ShaderX