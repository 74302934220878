<template>
    <div id="WebGL" ref="WebGL">
        
    </div>
</template>
<script>
import { mapState } from 'vuex'
import GLGallery from '../assets/scripts/webgl/Gallery.js'
const glg = new GLGallery();

export default {
    data() {
        return {
            isMovile: false,
            paused: false
        }
    },
    methods: {
        animation: function() {
            if(this.paused) return;
            requestAnimationFrame(this.animation);
            glg.animate();
        }
    },
    computed: mapState(['entryIndex']),
    watch: {
        entryIndex(newValue, oldValue){
            if (this.isMovile == false) {
                glg.moveCamera(newValue);
                console.log(oldValue);
            }
        },
        '$route.name' (value){
            if(value == 'Gallery') {
                this.paused = false;
                //console.log('despausado');
                this.animation();
            }
            else {
                this.paused = true;
                //console.log('pausado');
                
            }
        }
    },
    mounted: function() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.isMovile = true;
        }
        else {
            glg.init();
            this.animation();
        }
    }
}
</script>
<style lang="scss">
    canvas {
        position: fixed;
        top: 0;
        right: 0;
        z-index: -3;
    }
</style>