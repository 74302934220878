<template>
  <div id="gallery" class="gallery portfolio scrollable" @wheel="scrollHandler" v-pan="panHandler">
    <!-- <button @click="$store.commit('increaseEntryIndex')" >sumar</button>
    <button @click="$store.commit('decreaseEntryIndex')" >restar</button> -->
    <transition name="slide-fade" mode="out-in">
      <div class="gallery-entry" :key=$store.state.entryIndex >
        <div class="entry-title">
          <h3 class="entry-category">{{entries.entries[$store.state.entryIndex].kind}}</h3>
          <svg class="entry-name" height="50" width="100%" @click="toggled = !toggled">
            <text x="0" y="85%" fill="black" stroke="none" font-family="Spartan" >#{{$store.state.entryIndex + 1}} <tspan fill="none" stroke="black" stroke-width="1.5" >{{ entries.entries[$store.state.entryIndex].name }}</tspan></text>
          </svg>
          <!-- <h3 class="entry-concept">Lorem ipsum craftinh sihamet</h3> -->
        </div>
        <div class="entry-more" v-bind:class="{ open: toggled }" >
          <p class="entry-excerpt">{{entries.entries[$store.state.entryIndex].excerpt}}</p>
          <a class="entry-link" :href=entries.entries[$store.state.entryIndex].link> GO THERE</a>
        </div>
        <img class="portimage" v-if="isMovile" :src=entries.entries[$store.state.entryIndex].imageLink alt="">
      </div>
    </transition>
    <div class="scroller">
      <div class="arr up-arr" v-bind:class="{active: $store.state.entryIndex > 0 }"></div>
      <div class="scroll-title">Scroll</div>
      <div class="arr down-arr" v-bind:class="{active: $store.state.entryIndex < $store.state.limit}"></div>
    </div>

  </div>
</template>

<script>
//import { mapState } from 'vuex'
//import { mapMutations } from 'vuex'
import portfolio from '../assets/data/portfolio.json';
import Hammer from 'hammerjs';

export default {
  name: 'Gallery',
  data() {
    return {
      entries: portfolio,
      cooldown: false,
      evento: 'ninguno',
      isMovile: false,
      toggled: false
    }
  },
  directives: {
    pan: {
      // Definición de directiva
      mounted: function(el, binding) {
        if (typeof binding.value === "function") {
          console.log("event binded");
          const mc = new Hammer(el);
          mc.get("pan").set({ direction: Hammer.DIRECTION_ALL });
          mc.on("panup pandown", binding.value);
        }
      }
    }
  },
  methods: {
    panHandler (e) {
      console.log(e.type);  // May be left / right / top / bottom
      if (e.type == "panup" && e.isFinal == true) {
        console.log(e);
        this.$store.commit('increaseEntryIndex');
        //this.position = Math.min(Math.max(0, this.scrollLocation), 4);
      } else if (e.type == "pandown" && e.isFinal == true) {
        console.log(e);
        this.$store.commit('decreaseEntryIndex');
        //this.position = Math.min(Math.max(0, this.scrollLocation), 4);
      }
    },
    scrollHandler: function(event) {
      console.log(event);
      if (this.cooldown == false ) {
        if (event.deltaY >= 3) {
          this.$store.commit('increaseEntryIndex');  //////NOTA averiguar sobre event bubbling aqui https://www.sitepoint.com/event-bubbling-javascript/
          this.cooldown = true;
        }
        else if (event.deltaY <= -3) {
          this.$store.commit('decreaseEntryIndex'); //////NOTA controlar de que no se ejecute inmediatamente despues de activarlo por un segundo al menos
          this.cooldown = true;
        } 
      }
      //Cooldown
      setTimeout(() => this.cooldown = false, 1000);
    }
  },
  mounted () {
    window.scrollTo(0, 0);
    //this.$store.commit('resetEntryIndex');
    //console.log(this.entries);

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMovile = true;
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/utils.scss';

.gallery-entry {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  height: 75.4vh;
  width: 80%;
  margin: 0 auto;
  overflow-x: hidden;
  //margin-top: 12.3vh;
  @media (min-width: $brk-movile) {
    height: 100vh;
    width: 75%;
  }
  .portimage {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: auto;
    align-self: center;
    opacity: 0.5;
    //display: none;
  }
}

.entry-title {
  position: relative;
  //margin-bottom: 35px;
  width: auto;
}

.entry-name {
    height: 37px;
    width: 100%;
    width: 70vw;
    margin: 5px 0;
    @media (min-width: 580px) {
      height: calc(32px + 32 * ((100vw - 700px) / 580));
    }
    @media (min-width: 1200px) {
      height: 84px;
      width: 60vw;
    }
    text {
      font-family: "Spartan";
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
      x: 0;
      y: 85%;
      @media (min-width: 580px) {
        font-size: calc(32px + 32 * ((100vw - 700px) / 580));
      }
      @media (min-width: 1200px) {
        font-size: 74px;
      }
    }
    
}

.entry-category {
  @include entry-subtitles("top");
}

.entry-concept {
  @include entry-subtitles("bottom");
}

.entry-more {
  transition: 1s;
  width: 100%;
  max-width: 500px;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  &.open {
    max-height: 100%;
    opacity: 1;
  }
  .entry-link {
    color: #000000;
  }
  .entry-excerpt {
    line-height: 1.7;
  }
}

//Scroll Sign
.scroller {
  position: fixed;
  width: auto;
  right: 10%;
  bottom: 15%;
  transform-origin: right center;
  transform: rotate(90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  @media (min-width: 1200px) {
    bottom: 10%;
  }
  .arr {
    transition: 0.3s;
    display: block;
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
  .up-arr {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }
  .down-arr {
    border-right: 1px solid black;
    border-top: 1px solid black;
  }
  .scroll-title {
    margin: 0 10px;
  }
}

//Transition

//Router animation
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>
